<template>
    <div class="dosiin_page-min-height dosiin_padding-header-top">
        <div class="ds_notification-inner">
            <div class="ds_notification-right">
                <div class="ds_notification-box">
                    <div class="ds_notification-back_header">
                        <div class="ds_notification-go-back" @click="goBack">
                            <i class="dsi dsi-chevron-left"></i>
                            <a>Thông báo</a>
                        </div>
                        <h2 class="text-grey-lg">Sự kiện</h2>
                        <div class="ds_notification-dot-event">
                        </div>
                    </div>
                    <div class="ds_notification-scrolly d_noti-txtbox">
                        <div class="ds_notification-scrolly-content">
                            <section class="notification-mag-new pd_bt">
                                <template  v-if="state.events.length">
                                    <div class="notification-new-deal_wrapper read" v-for="(event,i) in state.events" :key="i">
                                        <div class="icon-left">
                                            <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83695 2.86144C4.83695 2.48166 5.14889 2.16699 5.52536 2.16699H6.21376C6.59023 2.16699 6.90217 2.48166 6.90217 2.86144V3.55588H13.0978V2.86144C13.0978 2.48166 13.4098 2.16699 13.7862 2.16699H14.4746C14.8511 2.16699 15.163 2.48166 15.163 2.86144V3.55588H17.2565C17.6249 3.55588 17.9167 3.87191 17.9167 4.23541V18.2111C17.9167 18.4105 17.8024 18.6031 17.6639 18.7021C17.5254 18.7997 17.3748 18.8337 17.2283 18.8337H2.77173C2.62518 18.8337 2.47459 18.7997 2.3361 18.7021C2.19761 18.6031 2.08333 18.4105 2.08333 18.2111V4.23541C2.08333 3.83529 2.40333 3.55588 2.77173 3.55588H4.83695V2.86144ZM6.21376 2.86144H5.52536V4.94477H6.21376V2.86144ZM14.4746 2.86144H13.7862V4.94477H14.4746V2.86144ZM4.83695 4.25033H2.77173V6.68088H17.2283V4.25033H15.163V4.94477C15.163 5.32454 14.8511 5.63921 14.4746 5.63921H13.7862C13.4098 5.63921 13.0978 5.32454 13.0978 4.94477V4.25033H6.90217V4.94477C6.90217 5.32454 6.59023 5.63921 6.21376 5.63921H5.52536C5.14889 5.63921 4.83695 5.32454 4.83695 4.94477V4.25033ZM12.2343 9.61735L12.4212 9.32574L12.9994 9.7028L12.8125 9.99441L9.5708 15.0685L7.12911 12.8414L6.875 12.6081L7.33617 12.094L7.59164 12.326L9.43097 14.0051L12.2343 9.61735Z" fill="#DA623D"></path>
                                            </svg>
                                        </div>
                                        <div class="notification-description">
                                            <div class="notification-description_img notification-item_img-wrapper">
                                                <router-link :to="{name : 'exclusive' , query : {event_id : event.id} }">
                                                    <img class="name-notification" v-lazy="_eventThumbnail(event.main_pair)" width="55" height="55" :alt="event.name">
                                                </router-link>
                                            </div>
                                            <div class="notification-description-event_info">
                                                <router-link class="info-deal" :to="{name : 'exclusive' , query : {event_id : event.id} }" :title="event.name">
                                                    <h5 class="notification-item_title truncate" v-text="event.name"></h5>
                                                    <span class="notification-item_title-sub">Sự kiện diễn ra: {{ _momentDateMonth(event.time_start)}} - {{_momentDateMonth(event.time_end)}}</span>
                                                    <p class="time" v-text="_momentFromNow(event.time_start)"></p>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <EmptyComponent v-else :title="true" />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ds_notification-left">
                <div class="brand-list-header header-section">
                    <h3 class="brand-suggested-brands_title">Thương hiệu bạn có thể biết</h3>
                    <div class="view-all">
                        <router-link class="primary-button" :to="{ name : 'brand'}" title="Xem tất cả thương hiệu">
                            <span class="primary-link_text">Toàn bộ</span>
                            <i class="dsi dsi-act-arrow-forward"></i>
                        </router-link>
                    </div>
                </div>
                <div class="dosiin_suggested-brands_slider suggested-brands_slider">
                    <ul class="ds_notification-left_list">
                        <li class="ds_notification-left_item" v-for="(brand,i ) in state.brands.slice(0,2)" :key="i">
                            <BrandItemVertical :brand="brand" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    export default {
        components:{
            BrandItemVertical
        },
        setup() {
            const state = inject('state');      

            return{ 
                state
            }
        },
    }
</script>