<template>
    <div class="modal modal-full-screen dosiin_events-modal open">
        <div class="modal-header">
            <header style="display: unset;">
                <div class="back-header dosiin_back-header-sticky">
                    <div class="go-back-icon">                    
                        <i class="dsi dsi-chevron-left"></i>                    
                        <button class="dosiin_btn-close-modal text" @click="goBack">Quay lại</button>                
                    </div>
                    <h2 class="text-grey-lg">
                        <div style="display:block;">Sự kiện</div>
                    </h2>
                    <div class="back-header-right-side"></div>
                </div>
            </header>
        </div>
        <div class="modal-body">
            <main class="notifications-events-page">
                <section class="notification-mag-new">
                    <div class="header-section">
                        <h2 class="notification-new-deal_title">Sự kiện đang diễn ra</h2>
                        <div class="mark-read-all"></div>
                    </div>
                    <div class="notification-new-deal_wrapper read" v-for="(event , i ) in state.events" :key="i">
                        <div class="icon-left">
                            <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83695 2.86144C4.83695 2.48166 5.14889 2.16699 5.52536 2.16699H6.21376C6.59023 2.16699 6.90217 2.48166 6.90217 2.86144V3.55588H13.0978V2.86144C13.0978 2.48166 13.4098 2.16699 13.7862 2.16699H14.4746C14.8511 2.16699 15.163 2.48166 15.163 2.86144V3.55588H17.2565C17.6249 3.55588 17.9167 3.87191 17.9167 4.23541V18.2111C17.9167 18.4105 17.8024 18.6031 17.6639 18.7021C17.5254 18.7997 17.3748 18.8337 17.2283 18.8337H2.77173C2.62518 18.8337 2.47459 18.7997 2.3361 18.7021C2.19761 18.6031 2.08333 18.4105 2.08333 18.2111V4.23541C2.08333 3.83529 2.40333 3.55588 2.77173 3.55588H4.83695V2.86144ZM6.21376 2.86144H5.52536V4.94477H6.21376V2.86144ZM14.4746 2.86144H13.7862V4.94477H14.4746V2.86144ZM4.83695 4.25033H2.77173V6.68088H17.2283V4.25033H15.163V4.94477C15.163 5.32454 14.8511 5.63921 14.4746 5.63921H13.7862C13.4098 5.63921 13.0978 5.32454 13.0978 4.94477V4.25033H6.90217V4.94477C6.90217 5.32454 6.59023 5.63921 6.21376 5.63921H5.52536C5.14889 5.63921 4.83695 5.32454 4.83695 4.94477V4.25033ZM12.2343 9.61735L12.4212 9.32574L12.9994 9.7028L12.8125 9.99441L9.5708 15.0685L7.12911 12.8414L6.875 12.6081L7.33617 12.094L7.59164 12.326L9.43097 14.0051L12.2343 9.61735Z" fill="#DA623D"></path>
                            </svg>
                        </div>
                        <div class="notification-description">
                            <div class="notification-description_img notification-item_img-wrapper">                                
                                <router-link :to="{name : 'exclusive' , query : {event_id : event.id} }">                                 
                                    <img class="name-notification" v-lazy="_eventThumbnail(event.main_pair)" width="55" height="55" :alt="event.name">                             
                                </router-link>                            
                            </div>
                            <div class="notification-description-event_info">
                                <router-link class="info-deal" :to="{name : 'exclusive' , query : {event_id : event.id} }">
                                    <h5 class="notification-item_title truncate" v-text="event.name"></h5>
                                    <span class="notification-item_title-sub">Sự kiện diễn ra: {{ _momentDateMonth(event.time_start)}} - {{_momentDateMonth(event.time_end)}}</span>                                    
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
export default {
    setup() {
        const state = inject('state');

        return { state }
    },
}
</script>