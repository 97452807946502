<template>
    <NotificatonsEventsMB v-if="layout == 4"/>
    <NotificatonsEventsPC v-else />
</template>

<script>
    import NotificatonsEventsPC from '@/pages/pc/notifications/NotificationsEvents';
    import NotificatonsEventsMB from '@/pages/mb/notifications/NotificationsEvents';
    import { reactive , provide } from 'vue';
    import EventService from '@/services/event';
    import BrandServices from '@/services/brand';
    import { serialize } from '@/utlis/serialize';
    export default {
        components:{
            NotificatonsEventsPC,
            NotificatonsEventsMB
        },
        setup(){
            const state = reactive({
                events : [],
                brands: [],
                loading : true
            });  

            getEvents();
            getBrands();

            async function getEvents (){
                const response = await EventService.fetchOriginEvent();
                if(response.data){
                    state.events = response.data.events;
                }
            }

            async function getBrands(){
                const response  = await BrandServices.fetchBrands(serialize({
                    limit : 2,
                    use_caching : true,
                    get_banner : true,
                    sort_by : 'random'
                }));

                if(response.data){
                    state.brands = response.data.brands;
                }
            }

            provide('state', state);
        }
    }
    
</script>